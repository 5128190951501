import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import './service-inner.css'
import PageHero from '../../components/pageHero'
import servicesHero from '../../images/services-hero.png'

const EducationPage = () => (
  <Layout>
    <SEO title="Development and Training" />
    <PageHero {...{title: "Staff & Consumer Training", image: servicesHero}}/>
    <div className="sp-content-wrapper flex">
      <div className="sp-content">
      <h1 className="title">FROM PLANNING TO GRAND OPENING...</h1>
      <p>Libation Consulting provides full-service training and beverage development for venues around the world. From the needs assessment and planning stages, to the grand opening and beyond, our experts prepare your team with everything needed to provide exceptional service, unique drinks and a memorable experience.</p>
      <p>Our team of consultants possess the experience and industry connections to source the highest quality and most cost-effective alcohol solutions. We will help you design a menu of handcrafted cocktails tailored to your establishment’s culture or event.</p>
      <p>We are at the forefront of providing training that help brands and establishments create safe and inclusive spaces for their staff and all customers who enjoy their products.</p>
      <p>Libation Consulting also provides bar space optimization to make the best use of your space for those who work within it.  We also facilitate additional team training sessions to familiarize your staff with the entire spectrum of beverage offerings. These services will guide your team and help to build a strong culture among your patrons.</p>
      <div className="service-inner-link-anchor">
      <a href="/case-studies/">View Case Studies</a>
      </div>
      </div>
    </div>
  </Layout>
)

export default EducationPage
